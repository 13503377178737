@import './variables.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.2;
  background-color: $color-grey-background;
}

ul[class],
ol[class] {
  list-style: none;
}

li {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

svg {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
}

main {
  flex: 1;
}

.container {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
  &--proposition {
    @media screen and (max-width: 500px) {
      padding: 0;
    }
  }
  &--recently-viewed {
    padding: 0;
  }
}
.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
  color: $color-black;
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
}

* {
	scrollbar-color: rgb(26, 117, 61) rgba(0, 0, 0, .7);
	scrollbar-width: thin;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	background-color: $color-light-grey;
	border-radius: 2px;
}
::-webkit-scrollbar-track {
	background-color: rgba(0, 0, 0, .7);
}
::-webkit-scrollbar-corner {
	background-color: rgba(0, 0, 0, .7);	
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}