@import '../../styles/variables.scss';

.logo {
    display: block;
    max-height: 100px;
    max-width: 160px;
    &--header {
        @media screen and (max-width: 900px) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 23px;
            
        }
    }
    &--footer {
        max-height: 100%;
        position: static;
        // padding-left: 30px;
        max-width: 100%;
        margin: 0 auto;
        @media screen and (max-width: 800px) {
            padding-left: 0;
        }
    }
    &__img {
        width: 120px;
        height: 75px;
        margin: 0 auto;
        &--header {
            @media screen and (max-width: 900px) {
                padding-top: 8px;
            }
        }
        &--footer {
            width: 150px;
            height: auto;
            margin: 0 auto;
            margin-bottom: 10px;
            @media screen and (max-width: 900px) {
            }
        }
    }
    &__text {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        color: $color-green;
        font-family: 'Spectral', serif;

        &--footer {
            display: block !important;
            font-size: 16px !important;
            @media screen and (max-width: 900px) {
                
            }
        }
        @media screen and (max-width: 900px) {
            display: none;
        }
    }
}