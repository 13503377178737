@import '../../styles/variables.scss';

.product-order-card {
    &__message {
        text-align: center;
        max-width: 100px;
        margin: 0 auto;
        margin-bottom: 20px;
        padding: 5px 10px;
        background-color: $color-orange;
        border-radius: 5px;
    }
    &__cart {
        position: relative;
        padding: 20px;
        border-radius: 6px;
        text-align: center;
        -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
        @media screen and (max-width: 700px) {
            margin: 0 auto;
        }
    }
    &__availability {
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        margin: 0 auto;
        margin-bottom: 10px;
        &--true {
            color: $color-green;
            max-width: 135px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $color-green;
            }
        }
        &--false {
            color: $color-red;
            max-width: 170px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $color-red;
            }
        }
    }
    &__title {
        font-size: 30px;
        text-align: center;
        margin-bottom: 10px;
        @media screen and (max-width: 700px) {
            max-width: 100%;
            text-align: center;
        }
    }
    &__price {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    &__new-price {
        font-size: 20px;
        & strong {
            font-size: 22px;
            color: $color-red;
        }
        & span {
            font-size: 16px;
            text-decoration: line-through;
            color: $color-light-grey;
        }
    }
    &__btn {
        width: 50%;
        padding: 10px 15px;
        font-size: 16px;
        border: 1px solid;
        color: $color-black;
        border-color: $color-green;
        background-color: $color-white;
        transition: background-color 0.3s, color 0.5s linear;
        &--cart {
            padding: 10px 15px;
            font-size: 16px;
            border: 1px solid;
            border-radius: 6px;
            color: $color-black;
            border-color: $color-green;
            background-color: $color-white;
            transition: background-color 0.3s, color 0.5s linear;
            &--active {
                display: flex;
                margin: 0 auto;
                padding: 10px 15px;
                font-size: 16px;
                border-radius: 6px;
                background-color: $color-green;
                color: $color-white; 
                transition: background-color 0.2s linear;
                & img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
                &:hover {
                    background-color: rgba($color-green, 0.8);
                }
            }
            &:hover {
                background-color: $color-green;
                color: $color-white;
            }
        }
        &--active {
            background-color: $color-green;
            color: $color-white; 
        }
        &:hover {
            background-color: $color-green;
            color: $color-white;
        }
    }
    &__btn-cart {
        font-size: 18px;
        padding: 5px 10px;
        border: 1px solid;
        border-color: $color-green;
        border-radius: 6px;
        transition: color 0.3s, background-color 0.3s;
        @media screen and (min-width: 900px) {
            &:hover {
                color: $color-white;
                background-color: $color-green;
            }
        }
    }
    &__quanity {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    &__summary {
        width: 165px;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 700;
        @media screen and (max-width: 750px) {
            width: 100%;
            margin-left: 0;
            margin-top: 10px;
        }
    }
    &__input {
        width: 80px;
        border: none;
        outline: none;
        text-align: center;
        overflow: hidden;
        background-color: inherit;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
    &__min-quanity {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
        color: rgba($color-black, 0.6);
    }
    &__num {
        display: block;
        width: 50px;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }
    &__subtitle {
        font-size: 20px;
    }
    &__sizes {
        // max-width: 250px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    &__size-item {
        position: relative;
    }
    &__in-cart {
        position: absolute;
        top: -9px;
        right: -6px;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: $color-light-grey;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 500px) {
            width: 19px;
            height: 19px;
            top: -8px;
        }
        & img {
            width: 15px;
            height: 15px;
            @media screen and (max-width: 500px) {
                width: 12px;
                height: 12px;
            }
        }
    }
    &__size {
        padding: 10px 15px;
        border: 1px solid $color-green;
        transition: color 0.2s linear, background-color 0.2s linear;
        border-radius: 6px;
        cursor: pointer;
        @media screen and (max-width: 600px) {
            padding: 8px 10px;
        }
        @media screen and (max-width: 500px) {
            padding: 5px 8px;
        }
        &--active {
            color: $color-white;
            background-color: $color-green;
        }
        &:hover {
            color: $color-white;
            background-color: $color-green;
        }
        &--promo {
            color: $color-black;
            border-color: $color-orange;
            &:hover {
                color: $color-black;
                background-color: $color-orange;
            }
        }
        &--promo--active {
            background-color: $color-orange;
        }
        &:disabled {
            opacity: 0.7;
            cursor: default;
        }
    }
    &__size-name {
        padding: 10px 15px;
        border: 1px solid $color-green;
        border-radius: 6px;
        background-color: #0000000a;
    }
}