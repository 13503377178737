@import '../../styles/variables.scss';

.history {
    padding-bottom: 50px;
    &__list {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        transform: translateY(100px);
        opacity: 0;
        transition: transform 0.4s ease-in, opacity 0.4s linear;
        padding-bottom: 50px;
        &::before {
            content: '';
            position: absolute;
            width: 105%;
            height: 2px;
            background-color: #5AF042;
            top: 48px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            @media screen and (max-width: 1280px) {
                width: 100%;
            }
            @media screen and (max-width: 900px) {
                display: none;
                // width: 2px;
                // height: 100%;
                // left: 0;
                // top: 0;
            }
        }
        &--active {
            transform: translateY(0px);
            opacity: 1;
        }
        @media screen and (max-width: 1280px) {
           padding-bottom: 100px;
        }
        @media screen and (max-width: 900px) {
            flex-direction: column;
            justify-content: flex-start;
            gap: 30px;
            padding-bottom: 0;
        } 
    }
    &__item {
        position: relative;
        z-index: 2;
        background-color: #F9F9F9;
        padding-bottom: 150px;
        
        @media screen and (max-width: 900px) {
            width: 80%;
            display: flex;
            align-items: center;
            padding-bottom: 0;
            &:first-child {
                &::after {
                    display: none;
                }
            }
        }
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        &::after {
            content: '';
            margin: 0 auto;
            margin-bottom: 10px;
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $color-grey-text;
            transform: translateY(-10px);
            @media screen and (max-width: 900px) {
                position: absolute;
                border-radius: 0;
                left: 32px;
                top: -26px;
                width: 2px;
                height: 30px;
                background-color: #5AF042;
                transform: translateY(0);
            } 
        }
    }
    &__line {
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
        display: block;
        width: 2px;
        background-color: $color-light-grey;
        @media screen and (max-width: 900px) {
            display: none;
        }
        &--short {
            bottom: 101px;
            height: 70px;
        }
        &--long {
            height: 150px;
        }
    }
    &__container {
        position: relative;
    }
    &__apple {
        margin: 0 auto;
    }
    &__year {
        position: absolute;
        z-index: 5;
        font-size: 16px;
        font-weight: 700;
        left: 16px;
        top: 40px;
    }
    &__text {
        width: 200px;
        position: absolute;
        left: 50%;
        font-size: 14px;
        line-height: 1.2;
        text-align: center;
        transform: translateX(-50%);
        @media screen and (max-width: 1280px) {
            width: auto;
        }
        @media screen and (max-width: 900px) {
            position: static;
            transform: translate(10px, 10px);
            text-align: start;
            font-size: 16px;
        }
        @media screen and (max-width: 600px) {
           max-width: 350px;
        }
        &--short {
            top: 150px;
        }
        &--long{
            top: 230px;
        }
        &::before {
            content: '';
            margin: 0 auto;
            margin-bottom: 10px;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color-grey-text;
            @media screen and (max-width: 900px) {
                display: none;
            } 
        }
    }
}