@import '../../styles/variables.scss';

.about-block {
    position: relative;
    padding: 50px 0;
    width: 100%;
    @media screen and (max-width: 900px) {
        padding: 30px 0;
    }
    &__inner {
        transform: translateY(100px);
        opacity: 0;
        transition: transform 0.4s ease-in, opacity 0.4s linear;
        &--active {
            transform: translateY(0px);
            opacity: 1;
        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        &:nth-child(even) {
            flex-direction: row-reverse;
        }
    }
    &__content {
        max-width: 450px;
        margin-right: 20px;
        @media screen and (max-width: 1050px) {
            max-width: 100%;
            margin-right: 0;
        }
        // @media screen and (max-width: 550px) {
        //     max-width: 100%;
        //     margin-right: 0;
        // }
    }
    &__title {
        position: relative;
        font-size: 30px;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 30px;
        &::after {
            content: '';
            position: absolute;
            bottom: -13px;
            left: 0;
            display: block;
            height: 3px;
            width: 100px;
            background-color: #bae1b6;
        }
        @media screen and (max-width: 1100px) {
            font-size: 25px;
        }
        @media screen and (max-width: 900px) {
            margin-bottom: 20px;
        }
    }
    &__text {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 20px;
        white-space: pre-wrap;
        color: $color-grey-text;
        @media screen and (max-width: 580px) {
            font-size: 16px;
        }
    }
    &__img {
        width: 500px;
        height: auto;
        border-radius: 6px;
        @media screen and (max-width: 1150px) {
            width: 500px;
        }
        @media screen and (max-width: 1050px) {
            margin: 0 auto;
        }
        @media screen and (max-width: 580px) {
            width: 100%;
        }
    }
}