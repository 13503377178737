.scroll-top {
    position: fixed;
    bottom: 20px;
    right: -100%;
    padding: 13px;
    border-radius: 50%;
    background-color: #bae1b6;
    transition: right 0.5s ease-in-out;
    z-index: 10;
    &--active {
        right: 20px;
    }
    &__img {
        position: relative;
        bottom: 2px;
        width: 20px;
        height: 20px;
        transform: rotate(-180deg);
    }
}