@import '../../styles/variables.scss';

.header {
    position: relative;
    padding: 16px 0;
    z-index: 5;
    background-color: $color-light-green;
    @media screen and (max-width: 900px) {
        padding-bottom: 26px;
        padding-top: 26px;
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 560px;
        @media screen and (max-width: 900px) {
            min-width: auto;
        }
    }
    &__cart {
        position: relative;
        width: 100%;
        max-width: 145px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        background-color: $color-green;
        border-radius: 6px;
        transition: transform 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
            transform: translateX(-10px);
        }
        @media screen and (max-width: 900px) {
            max-width: 40px;
            justify-content: center;
            padding: 8px;
        }
        // @media screen and (max-width: 600px) {
        //     width: 130px;
        //     padding: 10px;
        // }
    }
    &__cart-img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        @media screen and (max-width: 900px) {
            width: 25px;
            height: 25px;
            margin-right: 0;
        }
    }
    &__cart-text {
        color: $color-light-green;
        font-weight: 600;
        @media screen and (max-width: 900px) {
            display: none;
        }
    }
    &__count {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -10px;
        right: -10px;
        background-color: $color-light-grey;
        color: $color-light-green;
        width: 28px;
        height: 28px;
        text-align: center;
        border-radius: 50%;
        font-size: 14px;
        @media screen and (max-width: 900px) {
            width: 20px;
            height: 20px;
        }
    }
    &__mobile-menu {
        background-color: $color-light-green;
        display: none;
        left: -100%;
        padding-top: 90px;
        padding-bottom: 40px;
        position: fixed;
        top: 0;
        transition: left .3s ease-in-out;
        width: 100%;
        z-index: 46;
        &--active {
            left: 0;
        }
        @media screen and (max-width: 900px) {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
    &__login {
        margin: 0 20px;
        color: $color-white;
        &--comp {
            @media screen and (max-width: 900px) {
                display: none;
            }
        }
        &--tel {
            width: 30px;
            height: 30px;
            margin: 0 auto;
            margin-top: 30px;
        }
        & p {
            margin-right: 10px;
        }
        & img {
            width: 30px;
            height: 30px;
        }
    }
}

.overlay {
    position: fixed;
    background-color: transparent;
    &--active {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color-black, 0.6);
        z-index: 45;
    }
}