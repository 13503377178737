.pagination {
    width: 50%;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 950px) {
        width: 80%;
    }
    @media screen and (max-width: 450px) {
        width: 100%;
    }
    &__arrow {
        position: relative;
        bottom: 5px;
        padding: 8px;
        border-radius: 50%;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
        transition: background-color 0.2s linear;
        cursor: pointer;
        &:disabled {
            opacity: 0.6;
            cursor: default;
            &:hover {
                background-color: #ffff;
            }
        }
        &:hover {
            background-color: #bae1b6;
        }
        &--prev {
            left: -10px;
            & img {
                top: 0;
                left: -2px;
                transform: rotate(90deg);
            }
        }
        &--next {
            & img {
                top: 0;
                left: 2px;
                transform: rotate(-90deg);
            }
        }
    }
    &__nav {
        max-width: 400px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    &__page-num {
        cursor: pointer;
        border-radius: 6px;
        padding: 10px;
        font-size: 16px;
        transition: background-color 0.2s linear;
        margin-right: 10px;
        margin-bottom: 10px;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
        &--active {
            background-color: #bae1b6;
        }
        &:hover {
            background-color: #bae1b6;
        }
    }
    &__img {
        position: relative;
        width: 25px;
        height: 25px;
    }
}