@import '../../styles/variables.scss';

.order-page {
    padding-top: 30px;
    &__title {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    &__list {
        max-width: 900px;
        margin: 0 auto;
    }
    &__summ {
        margin-top: 20px;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        color: $color-green;
    }
    &__empty {
        max-width: 500px;
        margin: 0 auto;
        text-align: center;
        & img {
            width: 200px;
            height: 200px;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
                width: 150px;
                height: 150px;
            }
        }
        & a {
            max-width: 200px;
            margin: 0 auto;
            margin-top: 20px;
            display: block;
            padding: 10px 15px;
            font-size: 16px;
            border-radius: 6px;
            color: $color-white;
            background-color: $color-green;
            transition: background-color 0.2s linear;
            &:hover {
                background-color: rgba($color-green, 0.8);
            }
        }
        & p {
            font-size: 16px;
            line-height: 1.2;
        }
    }
}