@import '../../styles/variables.scss';

.faq {
    margin: 0 auto;
    &__title {
        position: relative;
        margin-bottom: 40px;
        font-size: 22px;
        text-align: center;
        &::after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            height: 3px;
            width: 150px;
            background-color: $color-black;
        }
    }
    &__item {
        position: relative;
        padding: 5px 25px;
        margin-bottom: 20px;
        border: 1px solid #b5e5b982;
        border-radius: 6px;
        cursor: pointer;
        transition: background-color 0.2s linear;
        &--active {
            background-color: #b5e5b982;
        }
        @media screen and (min-width: 900px) {
            &:hover {
                background-color: #b5e5b982;
            }
        }
        @media screen and (max-width: 450px) {
            padding: 5px 15px;
        }
    }
    &__heading {
        font-size: 18px;
        margin: 10px 0;
        padding-right: 30px;
        font-weight: 400;
        transition: transform 0.3s ease-in, opacity 0.3s linear;
        &--active {
            transform: translateX(60px);
            @media screen and (max-width: 450px) {
                transform: translateX(40px);
            }
        }
    }
    &__arrow {
        position: absolute;
        top: 17px;
        right: 20px;
        width: 20px;
        height: 20px;
        transform: rotate(0deg);
        transition: transform 0.5s linear;
        &--active {
            transform: rotate(180deg);
        }
    }
    &__wrapper {
        display: grid;
        grid-template-rows: 0fr;
        overflow: hidden;
        transition: grid-template-rows 0.5s;
        &--active {
            grid-template-rows: 1fr;
        }
    }
    &__answer {
        min-height: 0;
        transition: visibility 0.5s linear;
        visibility: hidden;
        font-size: 16px;
        padding-right: 10px;
        line-height: 1.4;
        color: $color-grey-text;
        white-space: pre-wrap;
        &--active {
            visibility: visible;
        }
    }
}