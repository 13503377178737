// colors
$color-white: #ffff;
$color-black: #000000;
$color-grey-text: #6b6868;
$color-green: #406343;
$color-light-green: #F6FFCE;
$color-red: #FF5757;
$color-orange: #FEB101;
$color-yellow: #ffdb12;
$color-light-grey: #b6b5b5;
$color-grey-background: #415e4308;