.slider-wrapper {
  max-width: 400px;
  @media screen and (max-width: 500px) {
    max-width: 350px;
  }
}

.slick-slide {
  transition: opacity 0.3s linear;
  & .default {
    opacity: 0.4;
  }
  & .main {
    opacity: 1;
  }
  & .active {
    opacity: 1;
  }
}

// .slick-slide .default {
//   opacity: 0.4;
// }
// .slick-slide .main {
//   opacity: 1;
// }
// .slick-slide .active {
//   opacity: 1 !important;
// }
.slick-slide-image {
  margin: 0 auto;
  width: auto;
  height: 350px;
  border-radius: 6px;
  @media screen and (max-width: 500px) {
    height: 250px;
  }
  &--thumbnails {
    width: auto;
    height: 100px;
    cursor: pointer;
    @media screen and (max-width: 500px) {
      height: 80px;
    }
  }
}





.slider-container {
    display: flex;
    flex-direction: column;
  }
  
  .slider-main {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    position: relative;
    @media screen and (max-width: 900px) {
        margin: 0 auto;
        width: 250px;
        height: 250px;
    }
  }
  
  .slider-slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .slider-slide.active {
    opacity: 1;
  }
  
  .slider-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slider-thumbnails {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .slider-thumbnail {
    width: 100px;
    height: 50px;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s ease;
  }
  
  .slider-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slider-thumbnail.active,
  .slider-thumbnail:hover {
    opacity: 1;
  }