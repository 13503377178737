@import '../../styles/variables.scss';

.video-block {
    position: relative;
    min-height: 500px;
    @media screen and (max-width: 650px) {
        min-height: 400px;
    } 
    @media screen and (max-width: 450px) {
        min-height: 300px;
    } 
    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.3s linear;
        &--active {
            opacity: 1;
        }
    }
    &__title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        text-align: center;
        color: $color-white;
        text-transform: uppercase;
        letter-spacing: 10px;
        @media screen and (max-width: 650px) {
            font-size: 35px;
        } 
        @media screen and (max-width: 450px) {
            font-size: 25px;
        }
    }
    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}