@import '../../styles/variables.scss';

.partners {
    padding: 50px 0;
    background: linear-gradient(to right top, #406343, #4e8253, #5da263, #6cc374, #7ce585);
    &__title {
        position: relative;
        font-size: 30px;
        text-align: center;
        margin-bottom: 45px;
        text-transform: uppercase;
        transform: translateY(-30px);
        opacity: 0;
        color: $color-white;
        transition: transform 0.4s ease-in, opacity 0.4s linear;
        &--active {
            transform: translateY(0px);
            opacity: 1;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            height: 3px;
            width: 150px;
            background-color: $color-white;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 30px;
        transform: translateY(-30px);
        opacity: 0;
        transition: transform 0.4s ease-in, opacity 0.4s linear;
        &--active {
            transform: translateY(0);
            opacity: 1;
        }
    }
    &__photo {
        width: 200px;
        height: 130px;
        @media screen and (max-width: 500px) {
            width: 150px;
            height: 100px;
        }
    }
}