@import '../../styles/variables.scss';

.search {
    position: relative;
    width: 550px;
    &--desktop {
        @media screen and (max-width: 900px) {
            display: none;
        }
    }
    &--mobile {
        width: 500px;
        margin: 0 auto;
        @media screen and (max-width: 530px) {
            width: 95%;
        }
        .search__input {
            position: static;
        }
        .search__inner {
            top: 58px;
            z-index: 20;
        }
        .search__search-icon {
            top: 15px;
        }
        .search__delete {
            top: 16px;
        }
        // @media screen and (max-width: 420px) {
        //     width: 90%;
        // } 
    }
    &__input {
        position: absolute;
        top: 15px;
        border: 2px solid;
        border-color: transparent;
        padding: 13px 15px;
        padding-left: 45px;
        margin-bottom: 20px;
        width: 100%;
        font-size: 16px;
        border-radius: 6px;
        outline: none;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
        &:focus {
            border-color: #bae1b6;
        }
    }
    &__search-icon {
        position: absolute;
        top: 30px;
        left: 15px;
        width: 20px;
        height: 20px;
    }
    &__delete {
        position: absolute;
        width: 15px;
        height: 15px;
        top: 33px;
        right: 10px;
        cursor: pointer;
    }
    &__inner {
        position: absolute;
        width: 100%;
        top: 75px;
        border-radius: 6px;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
        background-color: $color-white;
        overflow: hidden;
    }
    &__product-list {
        position: relative;
        z-index: 25;
    }
    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        text-align: center;
        min-height: 50px;
    }
    &__item {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        transition: background-color 0.2s linear;
        @media screen and (max-width: 450px) {
            padding: 15px 10px;
        }
        &:hover {
            background-color: #bae1b6;
        }
        &:not(:last-child) {
            border-bottom: 1px solid $color-light-grey;
        }
        & img {
            width: auto;
            height: 50px;
            border-radius: 6px;
            @media screen and (max-width: 450px) {
                height: 40px;
            }
        }
        & h2 {
            font-size: 16px;
            margin-bottom: 5px;
            @media screen and (max-width: 530px) {
                font-size: 14px;
                text-align: center;
            }
            @media screen and (max-width: 400px) {
                font-size: 12px;
            }
        }
        & span {
            // position: absolute;
            // left: 50%;
            // bottom: 13px;
            // transform: translateX(-50%);
            font-size: 14px;
            color: $color-light-grey;
            @media screen and (max-width: 530px) {
                font-size: 14px;
            }
        }
    }
    &__info {
        text-align: center;
        margin: auto;
        // @media screen and (max-width: 420px) {
        //     max-width: 300px;
        // }
    }
    &__price {
        font-size: 16px;
        @media screen and (max-width: 530px) {
            font-size: 14px;
        }
        &--promo {
            font-size: 16px;
            color: $color-red;
            @media screen and (max-width: 530px) {
                font-size: 14px;
            }
        }
        &--old {
            font-size: 14px;
            @media screen and (max-width: 530px) {
                font-size: 12px;
            }
            color: $color-light-grey;
            text-decoration: line-through;
            text-align: center;
        }
    }
    &__summary {
        position: absolute;
        bottom: -530px;
        left: 0;
        width: 100%;
        padding: 10px 15px;
        padding-bottom: 20px;
        font-size: 16px;
        text-align: center;
        background-color: $color-white;
        border-radius: 6px;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
        @media screen and (max-width: 900px) {
            bottom: -440px;
        }
        @media screen and (max-width: 530px) {
            font-size: 14px;
            bottom: -435px;
        }
        // @media screen and (max-width: 420px) {
        //     bottom: -496px;
        // }
        & p {
            margin-bottom: 20px;
        }
    }
    &__btn {
        padding: 10px 15px;
        color: $color-white;
        background-color: $color-green;
        border-radius: 6px;
        transition: color 0.2s linear, background-color 0.2s linear;
        &:hover {
            color: $color-black;
            background-color: #bae1b6;
        }
    }
}