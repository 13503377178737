@import '../../styles/variables.scss';

.link-back {
    display: flex;
    align-items: center;
    width: 120px;
    margin-bottom: 30px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    &:hover &__img {
        transform: translateX(-20px) rotate(90deg);
    }
    &__img {
        width: 35px;
        height: 35px;
        transition: transform 0.3s linear;
        transform: rotate(90deg);
    }
    span {
        font-size: 18px;
        margin-left: 10px;
        color: $color-green;
    }
}