.recently-viewed{
    margin-top: 20px;
    &--main {
        margin-top: 50px;
        padding-bottom: 30px;
    }
    &--product {
        margin-bottom: 50px;
    }
}

.container--recently-viewed {
    padding: 0;
}

.recents-product__title {
    padding: 0;
    padding-left: 15px;
    margin-bottom: 40px !important;
    @media screen and (max-width: 550px) {
        margin-bottom: 55px !important;
    }
}