.search-page {
    padding-top: 30px;
    &__inner {
        position: relative;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 550px) {
            flex-direction: column;
            align-items: normal;
        }
    }
    &__input-container {
        position: relative;
        & img {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
        }
        @media screen and (max-width: 550px) {
            margin-bottom: 30px;
        }
    }
    &__input {
        width: 500px;
        padding: 10px 15px;
        padding-left: 45px;
        border: 2px solid;
        border-color: transparent;
        outline: none;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        &:focus {
            border-color: #bae1b6;
        }
        @media screen and (max-width: 900px) {
            width: 400px;
        }
        @media screen and (max-width: 600px) {
            width: 300px;
        }
        @media screen and (max-width: 550px) {
            width: 350px;
        }
        @media screen and (max-width: 400px) {
            width: 300px;
        }
    }
    &__sorting {
        @media screen and (max-width: 550px) {
            top: 115px !important;
        }
    }
    &__count {
        font-size: 16px;
        margin-bottom: 20px;
    }
}