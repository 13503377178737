@import '../../styles/variables.scss'; 

.notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right: 50px;
    top: 140px;
    padding: 10px;
    max-width: 300px;
    opacity: 0;
    background-color: $color-green;
    border-radius: 6px;
    transition: opacity 0.2s linear;
    z-index: 1;
    @media screen and (max-width: 700px) {
       top: 70px;
    }
    &--active {
        opacity: 1;
    }
    &__img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    &__text {
        font-size: 16px;
        color: $color-white;
        @media screen and (max-width: 900px) {
            font-size: 14px;
        }
    }
}