@import '../../styles/variables.scss';

.language {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    &--mobile {
        margin: 0 auto;
        @media screen and (max-width: 900px) {
            display: flex !important;
        }
    }
    @media screen and (max-width: 900px) {
        display: none;
    }
    &__item {
        cursor: pointer;
        transition: color, 0.2s linear;
        color: rgba($color-black, 0.7);
        padding-left: 10px;
        padding-right: 10px;
        border-right: 2px solid $color-black;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            border-right: none;
        }
        &--active {
            color: $color-black;
        }
        &:hover {
            color: $color-black;
        }
    }
}