@import '../../styles/variables.scss';

.proposition {
    position: relative;
    margin-top: 50px;
    margin-bottom: 60px;
    &__title {
        padding-left: 15px;
        @media screen and (max-width: 550px) {
            margin-bottom: 50px !important;
        }
    }
    &__shadow {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateX(-50%);
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
        @media screen and (max-width: 1050px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 500px) {
            padding: 0 15px;
            grid-template-columns: repeat(1, 1fr);
        }
    }
}