@import '../../styles/variables.scss';

.packaging {
    text-align: center;
    margin: 50px 0;
    &__heading {
        font-size: 30px;
        margin-bottom: 10px;
    }
    &__text {
        font-size: 20px;
        margin-bottom: 30px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &__item {
        width: 20%;
        @media screen and (max-width: 1000px) {
            width: 33%;
            margin-bottom: 15px;
        }
        @media screen and (max-width: 700px) {
            width: 50%;
        }
        @media screen and (max-width: 450px) {
            width: 100%;
        }
    }
    &__title {
        position: relative;
        margin: 0 auto;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        padding-bottom: 10px;
        margin-bottom: 15px;
        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            bottom: -5px;
            height: 2px;
            width: 75%;
            background-color: $color-green;
            transform: translateX(-50%);
        }
    }
    &__img {
        margin: 0 auto;
        width: 180px;
        height: 180px;
        // @media screen and (max-width: 700px) {
        //     width: 130px;
        //     height: 130px;
        // }
    }
}