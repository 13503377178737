@import '../../styles/variables.scss';

.feedback-page {
    padding-top: 50px;
    text-align: center;
    &__title {
        font-size: 30px;
        margin-bottom: 20px;
        color: $color-white;
        @media screen and (max-width: 550px) {
            font-size: 25px;
        }
    }
    &__subtitle {
        margin-bottom: 10px;
    }
    &__banner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 300px;
        background-image: url('../../assets/img/feedback_bg.jpg');
        background-size: cover;
        border-radius: 6px;
        margin-bottom: 30px;
        z-index: -1;
        & p {
            color: #cccaca;
            @media screen and (max-width: 550px) {
                font-size: 16px;
            }
        }
        @media screen and (max-width: 700px) {
            background-position: center;
        }
        @media screen and (max-width: 550px) {
            min-height: 200px;
        }
    }
    &__content {
        position: relative;
        z-index: 5;
    }
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 2;
        border-radius: 6px;
    }
    &__text {
        font-size: 18px;
        margin-bottom: 30px;
        white-space: pre-wrap;
    }
    &__item {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        color: $color-green;
    }
    &__modal {
        margin-top: 30px;
    }
    &__modal-img {
        width: 400px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        border-radius: 8px;
        @media screen and (max-width: 550px) {
            width: 300px;
        }
        @media screen and (max-width: 400px) {
            width: 80%;
        }
    }
    &__modal-title {
        font-size: 20px;
        margin-bottom: 10px;
    }
    &__modal-text {
        font-size: 16px;
        margin-bottom: 10px;
    }
    &__form {
        max-width: 600px;
        justify-content: center;
    }
    &__text-area {
        resize: none;
        height: 200px;
    }
}