@import '../../styles/variables.scss';

.categories {
    margin-top: 30px;
    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        // grid-template-columns: repeat(4, 1fr);
        // grid-template-rows: repeat(3, 1fr);
        gap: 20px;
        align-items: center;
        // @media screen and (max-width: 700px) {
        //     grid-template-columns: repeat(2, 1fr);
        //     grid-template-rows: repeat(4, 1fr);
        // }
        @media screen and (max-width: 550px) {
            gap: 20px;
        }
        
    }
    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 270px;
        height: 270px;
        padding: 20px;
        background-color: $color-white;
        border-radius: 6px;
        text-align: center;
        transition: background-color 0.3s linear;
        -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
        @media screen and (max-width: 900px) {
            width: 250px;
            height: 250px;
        }
        @media screen and (max-width: 750px) {
            padding: 15px;
        }
        @media screen and (max-width: 700px) {
            width: 45%;
        }
        @media screen and (max-width: 520px) {
            padding: 10px;
        }
        @media screen and (max-width: 420px) {
            width: 160px;
            height: 160px;
            padding: 5px;
        }
        @media screen and (min-width: 900px) {
            &:hover {
                background-color: $color-green;
            }
            &:hover .categories__name {
                color: $color-white;
            }
        }
    }
    &__img {
        width: auto;
        height: 150px;
        margin: 0 auto;
        margin-bottom: 10px;
        @media screen and (max-width: 600px) {
            height: 120px;
        }
        @media screen and (max-width: 420px) {
            height: 80px;
        }
    }
    &__name {
        font-size: 18px;
        color: $color-black;
        transition: 0.3s linear;
        @media screen and (max-width: 700px) {
            font-size: 16px;
        }
        @media screen and (max-width: 450px) {
            font-size: 14px;
        }
    }
}


// &--1 {
        //     grid-area: 1 / 1 / 3 / 2; 
        //     width: 100%; 
        //     height: 100%; 
        //     background-color: #F5D4E7;
        //     @media screen and (max-width: 700px) {
        //         grid-area: 1 / 1 / 2 / 3;
        //     }
        // }
        // &--2 {
        //     grid-area: 1 / 2 / 2 / 3; 
        //     background-color: #FFF0B9;
        //     @media screen and (max-width: 700px) {
        //         grid-area: 2 / 1 / 3 / 2;
        //     }
        // }
        // &--3 {
        //     grid-area: 1 / 3 / 2 / 4; 
        //     background-color: #FFC7B8;
        //     @media screen and (max-width: 700px) {
        //         grid-area: 2 / 2 / 3 / 3;
        //     }
        // }
        // &--4 {
        //     grid-area: 2 / 2 / 3 / 4; 
        //     background-color: #E4F2A5;
        //     @media screen and (max-width: 700px) {
        //         grid-area: 3 / 1 / 4 / 3;
        //     }
        // }
        // &--5 {
        //     grid-area: 3 / 1 / 4 / 3; 
        //     background-color: #9ceea7;
        //     @media screen and (max-width: 700px) {
        //         grid-area: 4 / 1 / 5 / 2;
        //     }
        // }
        // &--6 {
        //     grid-area: 3 / 3 / 4 / 4; 
        //     background-color: #eed174;
        //     @media screen and (max-width: 700px) {
        //         grid-area: 4 / 2 / 5 / 3;
        //     }
        // }
        // &--7 {
        //     grid-area: 4 / 1 / 4 / 1; 
        //     background-color: #ee9074;
        //     @media screen and (max-width: 700px) {
        //         grid-area: 5 / 3 / 5 / 1;
        //     }
        // }