@import '../../styles/variables.scss';

.side-cart {
    position: fixed;
    right: -100%;
    top: 0;
    width: 450px;
    height: 100%;
    padding: 30px 15px;
    overflow: auto;
    background-color: $color-white;
    transition: right 0.5s ease-in-out;
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 550px) {
        width: 100%;
    }
    &--active {
        right: 0;
    }
    &__list {
        &--scroll {
            overflow-y: scroll;
        }
    }
    &__title {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }
    &__subtitle {
        font-size: 20px;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }
    &__bottom {
        &--inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        & span {
            width: 150px;
            text-align: center;
            font-size: 18px;
            padding: 10px 15px;
            border-radius: 6px;
            color: $color-white;
            background-color: $color-green;
        }
    }
    &__btn-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
    &__btn-clean {
        padding: 10px 25px;
        margin-bottom: 30px;
        border: 1px solid;
        border-radius: 6px;
        border-color: $color-red;
        transition: color 0.3s linear, background-color 0.3s linear;
        &:hover {
            color: $color-white;
            background-color: $color-red;
        }
    }
    &__confirm {
        padding: 10px 25px;
        border: 1px solid;
        border-radius: 6px;
        font-size: 16px;
        border-color: $color-green;
        transition: color 0.3s linear, background-color 0.3s linear;
        &:hover {
            color: $color-white;
            background-color: $color-green;
        }
    }
    &__empty {
        text-align: center;
    }
    &__empty-img {
        width: 240px;
        height: 240px;
        margin: 0 auto;
    }
    &__heading {
        font-size: 24px;
        font-weight: 700;
    }
}