@import '../../styles/variables.scss';

.burger {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 47;
    padding: 2px;
    @media(max-width: 900px) {
        display: block;
        position: static;
        left: 0;
        transform: translate(0);
        transition: left 0.3s linear;
    }
    &--active {
        .burger__item:nth-child(2) {
            opacity: 0;
        }
        .burger__item:nth-child(1) {
            transform: translateY(8px) rotate(45deg);
        }
        .burger__item:nth-child(3) {
            transform: translateY(-8px) rotate(-45deg);
        }
        @media(max-width: 900px) {
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__item {
        display: block;
        width: 28px;
        height: 3px;
        margin: 5px auto;
        background-color: $color-green;
        transition: all 0.5s ease-in-out;
    }
}