.spinner {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  width: 60px;
  height: 60px;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}