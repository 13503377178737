@import '../../styles/variables.scss';

.bread-crumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    &__item {
        position: relative;
        font-size: 16px;
        color: $color-light-grey;
        transition: color 0.3s linear;
        &:not(:last-child) {
            margin-right: 40px;
            &:hover {
                color: $color-green;
            }
        }
    }
    &__item:not(:last-child)::after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        right: -28px;
        transform: translateY(-50%);
        width: 15px;
        height: 1px;
        background-color: $color-light-grey;
    }
}

.product-page {
    position: relative;
    padding-top: 50px;
    font-size: 16px;
    min-height: 500px;
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }
    &__d-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color-light-grey;
        & p {
            margin-bottom: 30px;
        }
    }
    &__left {
        margin-right: 100px;
        @media screen and (max-width: 1000px) {
            margin-right: 50px;
        }
        @media screen and (max-width: 991px) {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }
    &__right {
        width: 50%;
        @media screen and (max-width: 900px) {
            width: 80%;
            margin: 0 auto;
        }
        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }
    &__img-container {
        max-width: 480px;
        overflow: hidden;
        height: 350px;
        border-radius: 6px;
        @media screen and (max-width: 500px) {
            max-width: 380px;
        }
        @media screen and (max-width: 400px) {
            max-width: 300px;
            height: 280px;
        }
    }
    &__img {
        height: 100%;
        @media screen and (max-width: 900px) {
            margin: 0 auto;
        }
    }
}