@import '../../styles/variables.scss';

.order-form {
    position: relative;
    &__container {
        max-width: 600px;
        margin: 0 auto;
    }
    &__title {
        margin: 30px 0;
    }
    &__field {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        & label {
            padding-bottom: 10px;
            font-size: 16px;
        }
    }
    &__input-text {
        padding: 10px 15px;
        border: 2px solid;
        border-color: transparent;
        border-radius: 6px;
        background-color: rgba(65, 94, 67, 0.031372549);
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
        outline: none;
        transition: all 0.2s linear;
        &--custom {
            width: 100%;
            // margin-bottom: 20px;
        }
        &:focus {
            border-color: #bae1b6;
        }
    }
    &__error {
        position: absolute;
        left: 0;
        bottom: -25px;
        font-size: 16px;
        color: $color-red;
    }
    &__order-btn {
        padding: 10px 15px;
        background-color: $color-green;
        border-radius: 6px;
        font-size: 16px;
        color: $color-white;
        width: 100%;
        max-width: 250px;
        margin: 0 auto;
        transition: background-color 0.2s linear;
        &:hover {
            background-color: rgba($color-green, 0.8);
        }
    }
    &__select {
        & option {
            &:hover {
                background-color: $color-green;
            }
        }
    }
    &__text {
        font-size: 16px;
        margin-bottom: 30px;
        & a {
            color: blue;
            transition: color 0.2s linear;
            &:hover {
                color: $color-green;
            }
        }
    }
    &__list {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        background-color: #bae1b6;
        border-color: $color-black;
        border-radius: 6px;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
        max-height: 300px;
        z-index: 200;
        @media screen and (max-width: 405px) {
            top: 90px;
        }
        &--active {
            overflow-y: scroll;
        }
        & li {
            font-size: 16px;
            padding: 4px 10px;
            cursor: pointer;
            transition: background-color 0.2s linear;
            &:hover {
                background-color:rgba(0, 0, 0, 0.3);
            }
            &:first-child {
                border-radius: 6px 6px 0 0;
            }
            &:last-child {
                border-radius: 0 0 6px 6px;
            }
        }
    }
    &__custom-select {
        width: 100%;
        min-height: 40px;
        padding: 10px 15px;
        border-radius: 6px;
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }
    &__select-fields {
        display: none;
        &--active {
            display: block;
        }
    }
    &__wrapper {
        &--active {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
        }
    }
    &__fail {
        font-size: 16px;
        color: $color-red;
        margin-bottom: 10px;
    }
}