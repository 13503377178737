@import '../../styles/variables.scss';

.error-block {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    text-align: center;
    color: $color-black;
    &__img {
        width: 250px;
        height: 250px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    &__title {
        font-size: 28px;
        margin-bottom: 10px;
    }
    &__text {
        font-size: 18px;
        color: $color-light-grey;
    }
}