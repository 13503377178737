@import '../../styles/variables.scss'; 

.slick-slide > div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.slick-track {
    padding: 15px 0;
}

.slick-prev {
    top: -40px !important;
    left: auto !important;
    right: 75px !important;
    @media screen and (max-width: 500px) {
        top: -35px !important;
    }
}

.slick-next {
    top: -40px !important;
    right: 30px !important;
    @media screen and (max-width: 500px) {
        top: -35px !important;
    }
}

.slick-next::before {
    font-size: 40px !important;
    color: $color-green !important;
}

.slick-prev::before {
    font-size: 40px !important;
    color: $color-green !important;
}

.slick-arrow .slick-next:hover {
    img {
        opacity: 0.8;
    }
}

.slick-dots {
    bottom: -30px !important;
}

.slick-dots li button:before {
    font-size: 11px !important;
}


.slick-dots li.slick-active button:before {
    font-size: 11px !important;
    color: $color-green !important;
}