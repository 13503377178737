@import '../../styles/variables.scss';

.footer {
    color: $color-black;
    margin-top: 30px;
    padding: 30px 0;
    background-color: $color-light-green;
    @media screen and (max-width: 800px) {
        padding: 25px 0;
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 800px) {
            flex-direction: column;
            gap: 10px;
        }
    }
    &__list {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &--nav {
            text-align: center;
        }
        &--contacts {
            @media screen and (max-width: 800px) {
                padding-left: 75px;
            }
        }
        @media screen and (max-width: 800px) {
            margin: 0 auto;
        }
    }
    &__left {
        margin-right: 60px;
    }
    &__column {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 800px) {
            margin-top: 30px;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 30px;
        text-align: center;
        @media screen and (max-width: 800px) {
            text-align: center;
            margin-bottom: 10px;
        }
    }
    &__item {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.4;
        max-width: 210px;
    }
    &__link {
        color: rgba($color-black, 0.5);
        // padding-bottom: 5px;
        // border-bottom: 2px solid;
        // border-color: transparent;
        transition: color 0.3s linear;
        &:hover {
            color: $color-black;
        }
        &--contact {
            position: relative;
            padding-bottom: 0px;
            border-bottom: none;
            border-color: transparent;
            // display: flex;
            // align-items: center;
            span {
                color: rgba($color-black, 0.5);
                // padding-bottom: 5px;
                // border-bottom: 2px solid;
                // border-color: transparent;
                transition: color 0.3s linear;
            }
            &:hover .footer__icon{
                fill: $color-black;
            }
            &:hover span {
                color: $color-black;
            }
        }
    }
    &__icon {
        position: absolute;
        top: 2px;
        left: -35px;
        width: 20px;
        height: 20px;
        fill: rgba($color-black, 0.5);
        margin-right: 10px;
        transition: fill 0.3s linear;
    }
    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        &--top {
            display: none;
            @media screen and (max-width: 800px) {
                display: block;
            }
        }
        &--center {
            @media screen and (max-width: 800px) {
                display: none;
            }
        }
    }
}