@import '../../styles/variables.scss';

.contact-page {
    padding-top: 50px;
    color: $color-white;
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;
        @media screen and (max-width: 1100px) {
            flex-direction: column;
        }
    }
    &__info {
        margin-bottom: 30px;
        padding: 20px;
        width: 100%;
        max-width: 800px;
        background-color: $color-green;
        border-radius: 6px;
        @media screen and (max-width: 1100px) {
            display: flex;
            justify-content: space-between;
        }
        @media screen and (max-width: 650px) {
            flex-direction: column;
        }
    }
    &__top-info {
        margin-bottom: 30px;
    }
    &__title {
        font-size: 20px;
        margin-bottom: 10px;
        &--location {
            color: $color-black;
        }
    }
    &__item {
        p {
            font-size: 16px;
        }
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    &__list {
        & li {
            font-size: 16px;
            max-width: 350px;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
    &__link {
        padding-left: 30px;
        font-weight: 600;
        line-height: 1.3;
        color: rgba($color-white, 0.5);
        position: relative;
        transition: color 0.2s linear;
        &:hover {
            color: $color-white;
            & .contact-page__icon {
                fill: $color-white;
            }
        }
    }
    &__icon {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 20px;
        height: 20px;
        fill: rgba($color-white, 0.5);
        transition: fill 0.2s linear;
    }
    &__schedule {
        &--bottom {
            width: 600px;
            margin: 0 auto;
        }
    }
    &__schedule-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        font-size: 16px;
    }
}

.location {
    width: 700px;
    height: 400px;
    border: 0;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 1100px) {
        width: 800px;
        height: 500px;
    }
    @media screen and (max-width: 850px) {
        width: 600px;
        height: 400px;
    }
    @media screen and (max-width: 650px) {
        width: 500px;
        height: 300px;
    }
    @media screen and (max-width: 550px) {
        width: 400px;
        height: 300px;
    }
    @media screen and (max-width: 450px) {
        width: 350px;
        height: 300px;
    }
    @media screen and (max-width: 400px) {
        width: 300px;
        height: 300px;
    }
}