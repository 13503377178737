@import '../../styles/variables.scss';

.categories-page {
    padding-top: 50px;
    position: relative;
    &__list {
        margin-right: 30px;
        margin-bottom: 30px;
    }
    &__item {
        display: block;
        width: 500px;
        padding: 10px 15px;
        font-size: 16px;
        border-top: 1px solid;
        border-left: 1px solid;
        border-right: 1px solid;
        border-color: $color-green;
        &:last-child {
            border-bottom: 1px solid;
        }
        &--active {
            color: $color-white;
            background-color: $color-green
        }
    }
    &__overlay {
        position: absolute;
        &--active {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 3;
        }
    }
}