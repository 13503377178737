@import '../../styles/variables.scss';

.hero {
    position: relative;
    width: 100%;
    &__slide {
        padding: 40px 100px;
        min-height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1150px) {
            min-height: 390px;
        }
        @media screen and (max-width: 900px) {
            min-height: 300px;
            padding: 40px 60px;
        }
        @media screen and (max-width: 700px) {
            min-height: 235px;
        }
        @media screen and (max-width: 500px) {
            // background-position: center;
            padding: 40px;
            min-height: 175px;
        }
    }
    &__title {
        font-size: 40px;
        font-weight: 700;
        color: $color-white;
        margin-bottom: 5px;
        @media screen and (max-width: 900px) {
            font-size: 30px;
        }
        @media screen and (max-width: 600px) {
            font-size: 20px;
        }
    }
    &__text {
        font-size: 24px;
        color: #e4e2e2;
        margin-bottom: 30px;
        @media screen and (max-width: 600px) {
            font-size: 18px;
        }
    }
    &__link {
        display: block;
        max-width: 150px;
        text-align: center;
        padding: 10px 15px;
        margin-top: 25px;
        color: $color-white;
        background-color: $color-green;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 600;
        transition: color 0.2s linear, background-color 0.2s linear;
        &:hover {
            background-color: $color-white;
            color: $color-green;
        }
    }
}
.swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
}

.swiper-pagination-bullet-active {
    background-color: $color-white !important;
}

.swiper-button-next,
.swiper-button-prev {
    width: 35px !important;
    height: 35px !important;
    border-radius: 50%;
    transition: opacity 0.2s linear;
    background-repeat: no-repeat;
    background-size: cover;
    &:hover {
       opacity: 0.8;
    }
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}
.swiper-button-next {
    background-image: url('../../assets/img/arrow_right.png');
}
.swiper-button-prev {
    background-image: url('../../assets/img/arrow_left.png');
}