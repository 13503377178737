@import '../../styles/variables.scss';

.gallery {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    &__title {
        position: relative;
        font-size: 30px;
        text-align: center;
        margin-bottom: 45px;
        text-transform: uppercase;
        transform: translateY(-30px);
        opacity: 0;
        transition: transform 0.4s ease-in, opacity 0.4s linear;
        &--active {
            transform: translateY(0px);
            opacity: 1;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            height: 3px;
            width: 150px;
            background-color: $color-black;
        }
    }
    &__list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        opacity: 0;
        transition: opacity 0.4s linear;
        &--active {
            opacity: 1;
        }
        @media screen and (max-width: 980px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 560px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__photo {
        position: relative;
        margin: 0 auto;
        width: 350px;
        height: auto;
        cursor: pointer;
        justify-self: center;
        &--rectangle {
            position: relative;
            width: auto;
            height: 455px;
            margin: 0 auto;
            cursor: pointer;
            .gallery__img {
                object-fit: inherit;
                aspect-ratio: inherit;
            }
        }
        @media screen and (max-width: 1130px) {
            width: 300px;
            // height: 300px;
            &--rectangle {
                // width: 300px;
                height: 390px;
            }
        }
        @media screen and (max-width: 680px) {
            width: 250px;
            // height: 250px;
            &--rectangle {
                width: auto;
                height: 325px;
            }
        }
        @media screen and (max-width: 560px) {
            width: 350px;
            // height: 350px;
            &--rectangle {
                // width: 350px;
                height: 455px;
            }
        }
        @media screen and (max-width: 460px) {
            width: 300px;
            // height: 300px;
            &--rectangle {
                // width: 300px;
                height: 390px;
            }
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1;
        border-radius: 6px;
        transition: transform 0.2s linear;
        @media screen and (min-width: 900px) {
            &:hover {
                transform: scale(1.01);
            }
        }
    }
    &__modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 50;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    &__count {
        position: absolute;
        color: $color-white;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        @media screen and (max-width: 650px) {
            bottom: 100px;
        }
        @media screen and (max-width: 450px) {
            bottom: 175px;
        }
    }
    &__btn {
        position: fixed;
        cursor: pointer;
        opacity: 0.6;
        color: #fff;
        z-index: 100;
        transition: opacity 0.2s linear;
        @media screen and (min-width: 900px) {
            &:hover {
                opacity: 1;
            }
        }
    }
    &__close {
        top: 40px;
        right: 40px;
    }
    &__prev {
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        padding: 10px;
        border-radius: 50%;
        background-color: #ffff;
        & img {
            position: relative;
            left: -1px;
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
        }
    }
    &__next {
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
        padding: 10px;
        border-radius: 50%;
        background-color: #ffff;
        & img {
            position: relative;
            left: 1px;
            width: 20px;
            height: 20px;
            transform: rotate(-90deg);
        }
    }
    &__full-img {
        width: calc(100% - 40px);
        height: 85%;
        // max-height: 700px;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
            max-width: 100%;
            max-height: 100%;
            pointer-events: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;   
        }
    }
}