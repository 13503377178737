@import '../../styles/variables.scss';

.product {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    padding: 15px;
    border-radius: 6px;
    background-color: $color-white;
    height: 400px;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    transition: transform 0.3s ease-in-out;
    z-index: 4;
    @media screen and (max-width: 900px) {
        height: 420px;
    }
    @media screen and (max-width: 540px) {
        height: 410px;
    }
    &--catalog {
        @media screen and (max-width: 550px) {
            height: 350px;
            padding: 10px;
        }
        @media screen and (max-width: 550px) {
            .product__title {
                font-size: 14px;
                min-height: 34px;
            }
            .product__old-price {
                font-size: 14px;
            }
            .product__new-price {
                font-size: 16px;
            }
        }
        @media screen and (max-width: 440px) {
            height: 315px;
            .product__btn {
                padding: 5px 8px !important;
            }
        }
        @media screen and (max-width: 400px) {
            .product__btn {
                padding: 5px 8px !important;
            }
        }
    }
    &--disable {
        opacity: 0.3;
        cursor: default;
        &:hover {
            transform: translateY(0px);
        }
    }
    @media screen and (max-width: 1050px) {
        text-align: center;
    }
    &:hover {
        transform: translateY(-8px);
        @media screen and (max-width: 1050px) {
            transform: translateY(0px);
        }
    }
    &__img-container {
        position: relative;
        max-width: 230px;
        overflow: hidden;
        height: 200px;
        margin: 0 auto;
        margin-bottom: 20px;
        border-radius: 6px;
        @media screen and (max-width: 580px) {
            &--catalog {
                max-width: 180px;
            }
        }
        @media screen and (max-width: 550px) {
            &--catalog {
                height: 180px;
            }
        }
        @media screen and (max-width: 500px) {
            &--catalog {
                height: 160px;
            }
        }
        @media screen and (max-width: 450px) {
            &--catalog {
                max-width: 140px;
            }
        }
        @media screen and (max-width: 440px) {
            &--catalog {
                height: 130px;
            }
        }
    }
    &__message {
        position: absolute;
        bottom: 0;
        left: -5px;
        padding: 5px 10px;
        background-color: $color-orange;
        border-radius: 5px;
    }
    &__img {
        // max-width: 230px;
        margin: 0 auto;
        height: 100%;
        // border-radius: 6px;
        // @media screen and (max-width: 550px) {
        //     max-width: 150px;
        // }
    }
    &__title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        min-height: 40px;
        @media screen and (max-width: 580px) {
            margin-bottom: 10px;
        }
    }
    &__sizes {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;
        justify-content: center;
        @media screen and (max-width: 450px) {
            gap: 5px;
        }
        @media screen and (max-width: 400px) {
            gap: 5px;
            margin-bottom: 5px;
        }
    }
    &__notification {
        font-size: 12px;
        color: $color-grey-text;
        margin-bottom: 5px;
    }
    &__btn {
        padding: 5px 10px;
        border: 1px solid $color-green;
        border-radius: 5px;
        transition: color 0.2s linear, background-color 0.2s linear;
        @media screen and (max-width: 1200px) {
            padding: 5px 8px;
        }
        &:hover {
            color: $color-white;
            background-color: $color-green;
        }
        &--promo {
            color: $color-black;
            border-color: $color-orange;
            &:hover {
                color: $color-black;
                background-color: $color-orange;
            }
        }
        &--promo--active {
            background-color: $color-orange;
        }
        &--active {
            background-color: $color-green;
            color: $color-white;
            border-color: $color-green;
        }
        &:disabled {
            opacity: 0.7;
            cursor: default;
        }
    }
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__price {
        font-size: 18px;
        color: $color-green;
        &--catalog {
            @media screen and (max-width: 450px) {
                font-size: 16px;
            }
        }
    }
    &__old-price {
        font-size: 16px;
        color: $color-light-grey;
        text-decoration: line-through;
    }
    &__new-price {
        font-size: 18px;
        color: $color-red;
    }
}